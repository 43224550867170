import React, { useState } from "react"

const Modal: React.FC = ({ children }) => {
  const [active, setActive] = useState(true)

  return (
    <div id="modal" className={`modal ${active ? "is-active" : ""}`}>
      <div className="modal-background" onClick={() => setActive(false)}></div>
      <div className="modal-content">
        <div className="box">
          <div className="content">{children}</div>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => setActive(false)}
      ></button>
    </div>
  )
}

export default Modal
