import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Modal from "../components/Modal"

const IndexPage: React.FC = () => (
  <Layout>
    <SEO title="Strona główna" />
    <Modal>
      <p className="has-text-centered">
        W związku z drastycznym wzrostem kursu dolara amerykańskiego jesteśmy
        zmuszeni do podniesienia cen naszych produktów o 10%.
      </p>
    </Modal>
    <section className="hero is-fullheight-with-navbar-and-footer big-hero">
      <div className="hero-body">
        <img className="hero-image" src="/bn.png" />
        <div className="container">
          <h1 className="is-size-2">
            Wyłączny dystrybutor
            <br /> marki<span className="has-text-primary"> Kuwotech</span>
          </h1>
          <p style={{ paddingTop: "1.5rem" }}>
            <Link to="/kontakt">
              <button className="button is-medium is-primary">
                Skontaktuj się z nami
              </button>
            </Link>
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
